.platforms-table-list {
	&__item {
		padding: 1rem 1.5rem;
		grid-template-columns: 2rem 15rem 15rem 10rem 7rem 5rem 8rem 5rem;
		//margin: 1rem 0
		justify-items: stretch;
		border-bottom: 1px solid transparentize($c_gray_bg, .7);

		&:hover {
			background: linear-gradient(90deg, rgba(239,239,255,0) 0%, rgba(245,245,255,1) 10%, rgba(245,245,255,1) 90%, rgba(239,239,255,0) 100%);

			.line-fade:after {
				background: linear-gradient(to right, rgba(239,239,255,0), rgba(245,245,255,1)); } }

		& > *:nth-last-child(1), & > *:nth-last-child(2) {
			text-align: center; }
		& * {
			font-weight: 300; }
		& > *:nth-child(2) {
			font-weight: 400; } }



	&__head-item {
		background: var(--c_select_bg);
		border: none;
		border-radius: .5rem;
		justify-items: stretch;
		* {
			font-weight: 400 !important; }
		&:hover {
			background: var(--c_select_bg); } } }

.loader_container {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 200px; }

.platforms_table_info {
	margin-bottom: 2rem; }

.js-show-selected {
	cursor: pointer; }

.modal_table {
	max-height: 60vh;
	overflow-x: auto; }

.saved_platforms_list__table {
	.grid-table__row {
		padding: 1.5rem 1.6rem;
		grid-template-columns: 1fr 10rem 10rem 8rem;
		align-items: center;
		border-bottom: 1px solid transparentize($c_gray_bg, .7);

		.title {
			margin: 0; } }

	.platforms-table-list__head-item {
		border: none;
		padding: .8rem 1.6rem; } }

.grid-table__row {
	.cross {
		width: 20px;
		height: 20px;
		&:before, &:after {
			background: var(--c_gray_bg); } }
	.flex_row {
		align-items: center;
		gap: .8rem; }

	.js-delete {
		cursor: pointer; } }


.js-show-detail {
	cursor: pointer; }

.selected_platform_list {
	font-size: var(--fs_title);
	color: var(--c_main_dark);
	--cross_color: var(--c_gray_bg);
	display: flex;
	gap: 2.3rem;
	padding: 1.1rem 1.25rem;
	border: 1px solid var(--c_second);
	border-radius: .5rem;
	width: max-content;
	align-items: center;
	font-weight: 700; }
