.content {
	max-width: 1440px;
	min-width: 1024px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0 50px;
	width: 100%;

	&.visible {
		overflow: visible;
	}
}

hr {
	border: none;
	border-bottom: 1px solid rgba(179, 179, 179, 0.3);
	margin: 2rem 0;
}

h1 {

}

.title-big {
	font-size: $fs_big_title;
	font-weight: 700;
}

.title-mid {
	font-size: $fs_title_mid;
	font-weight: 700;
}

.title {
	font-size: $fs_title;
	font-weight: 700;
}


.title2 {
	font-size: $fs_title2;
}

.small {
	font-size: $fs_small;
}

.light {
	font-weight: 300;
}

.main-margin {
	margin-bottom: 50px;
}

.c-main {
	color: $c_main;
}

.c-main-dark {
	color: $c_main_dark;
}

.c-gray {
	color: $c_gray;
}

.c-gray-bg {
	color: $c_gray_bg;
}

.c-red {
	color: $c_red;
}

.flex {
	display: flex;
}

.jc-sb {
	justify-content: space-between;
}

.va-m {
	vertical-align: middle;
	align-items: center;
}

.va-b {
	vertical-align: bottom;
	align-items: flex-end;
}

.ta-r {
	text-align: right;
}

.ta-c {
	text-align: center;
}

.w100 {
	width: 100%;
}

.mt-20 {
	margin-top: 20px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-40 {
	margin-bottom: 40px;
}

.mb-80 {
	margin-bottom: 80px;
}
.formFooter{
	display: flex;
	gap: 30px;
}

.btn {
	position: relative;
	padding: 24px 25px;
	display: inline-block;
	cursor: pointer;
	text-decoration: none;
	color: #fff;
	border-radius: 8px;
	box-shadow: -20px 22px 50px rgba(111, 90, 173, 0.48);
	text-align: center;
	box-sizing: border-box;
	font-size: $fs_title;
	font-weight: 700;
	overflow: hidden;
	z-index: 1;
	transition: all .3s ease-in-out;
	outline: none;
	-webkit-appearance: none;
	border: none;
	vertical-align: middle;

	svg {
		fill: #fff;
		display: inline-block;
		vertical-align: middle;

		&:first-child {
			margin-right: 12px;
		}

		&:last-child {
			margin-left: 12px;
		}
	}

	&.big {
		padding: 27px 50px;
	}

	&.small {
		padding: 12px 35px;
		font-size: 1em;
	}

	&:before {
		content: '';
		@include fill();
		background: $c_main;
		z-index: -1;
	}

	&:after {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		background: $c_second;
		width: 150%;
		padding-bottom: 100%;
		border-radius: 50%;
		z-index: -1;
		transform: translateX(-70%) translateY(50%) scale(0.2);
		transition: transform .4s ease-out;
		will-change: transform;
	}

	&:hover {
		box-shadow: -20px 22px 50px rgba(255, 149, 0, 0.31);

		&:after {
			transform: translateX(-10%) translateY(-50%) scale(1.2);
		}
	}
}

.cross {
	position: relative;
	width: 15px;
	height: 15px;

	&:before, &:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		width: 100%;
		height: 2px;
		background: white;
		@include rotate(45deg);
	}

	&:after {
		@include rotate(-45deg);

	}
}

.gamburger {
	display: inline-block;
	position: relative;
	height: 20px;
	width: 20px;
	border-bottom: 3px solid black;
	@include transition(all, 0.4s);

	&:after, &:before {
		content: '';
		left: 0;
		width: 100%;
		height: 3px;
		position: absolute;
		top: 66%;
		background-color: black;
		transition: all 0.5s;
	}

	&:before {
		top: 30%;
	}

	&.active {
		border-bottom: 3px solid transparent;

		&:after {
			@include rotate(45deg);
			top: 67%;
		}

		&:before {
			@include rotate(-45deg);
			top: 63%;
		}
	}
}

[aria-disabled='true'] {
	opacity: 0.5;
	pointer-events: none;
	cursor: wait;
}
.react-loading-skeleton{
	cursor: wait;
}

.input-cols {
	.col {
		padding-top: 0 !important;
		padding-bottom: 0 !important;
	}
}

.cols-2 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 50%;
		box-sizing: border-box;
	}

	&.p5 {
		@include col-padding(5px);
	}

	&.p15 {
		@include col-padding(15px);
	}

	&.p40 {
		@include col-padding(40px);
	}
}

.cols-3 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 33.333%;
		box-sizing: border-box;
	}

	&.p15 {
		@include col-padding(15px);
	}

	&.p10 {
		@include col-padding(10px);
	}
}

.cols-4 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 25%;
		box-sizing: border-box;
	}

	&.p7 {
		@include col-padding(7px);
	}

	&.p20 {
		@include col-padding(20px);
	}
}

.cols-5 {
	display: flex;
	flex-wrap: wrap;

	.col {
		width: 20%;
		box-sizing: border-box;
	}

	&.p22 {
		@include col-padding(22px);
	}
}

.badge {
	display: inline-block;
	padding: 0.35em 0.65em;
	font-size: .75em;
	font-weight: 700;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: 0.25rem;
	background-color: $c_main;
	margin-left: 10px;

	&.primary {
		background-color: $c_main;
	}

	&.secondary {
		background-color: $c_gray;
	}

	&.success {
		background-color: #198754;
	}

	&.danger {
		background-color: #dc3545;
	}

	&.warning {
		background-color: #ffc107;
		color: $color_black;
	}

	&.info {
		background-color: $c_second;
	}
}

.delayed_message{
	display: block;
	text-align: center;
	padding: 10px;
	opacity: 0;
	animation-name: delayed_message_animation;
	animation-duration: 300ms;
	animation-delay: 5s;
	animation-fill-mode: forwards;
	animation-direction: alternate;
	animation-play-state: running;
}

@keyframes delayed_message_animation {
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}